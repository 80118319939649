<template>
  <validation-provider  ref="textFieldProvider" slim v-slot="{errors, passed}" :name="name" :vid="id" :rules="rules" :mode="mode ? mode : 'eager'">
    <v-text-field
      :dark="darkTheme"
      data-vv-validate-on="input"
      v-bind="$attrs"
      :error-messages="errors"
      :name="id"
      :value="value"
      @input="$emit('input', $event, passed)"
      @click="$emit('click', $event)"
      @keyup="$emit('keyup', { $event , id })"
      @blur="$emit('blur', { $event , id })"
      ref="textField"
      :disabled="disabled"
      :hide-details="hideDetails"
    >
      <template v-slot:prepend-inner>
        <div class="primary--text prepend-text" v-if="textPrepend">
          {{ textPrepend }}
        </div>
      </template>
      <template v-slot:append-outer>
        <slot></slot>
        <div class="icon-append" v-if="iconAppend">
          <v-icon color="white">{{ iconAppend }}</v-icon>
        </div>
      </template>
    </v-text-field>
  </validation-provider>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      required: true,
    },
    textPrepend: {
      required: false,
      default: null,
    },
    iconAppend: {
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    autoBlur: {
      type: Number,
    },
    mode: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newValue) {
      if (newValue && this.autoBlur) {
        this.blur(newValue);
      }
    },
  },
  methods: {
    async blur(value) {
      if (value.length === this.autoBlur) {
        const { valid } = await this.$refs.textFieldProvider.validate();
        if (!valid) return;
        this.$emit('blurEvent');
        this.$refs.textField.blur();
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/variables";

.v-text-field__slot {
  padding: 8px 0 4px 0;
}

.v-text-field input {
  padding: 0px!important;
}
.prepend-text {
  height: 56px;
  top: 0;
  margin: -15px 0 0 0;
  line-height: 56px;
  font-size: 1.3rem;
  padding: 0 5px 0 0;
}

.icon-append {
  height: 55px;
  width: 55px;
  margin: -17px 0 0 -8px;
  line-height: 55px;
  padding: 0 5px 0 15px;
  background-color: $azul-qualicorp-hex;
  border-radius: 0 5px 5px 0;
}
</style>
