var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "themelogout",
      style: { background: _vm.$store.getters.getColor("bgColorSecundary") },
    },
    [
      _c(
        "v-container",
        { staticClass: "fill-height pa-8" },
        [
          _c(
            "v-row",
            { staticClass: "mb-0", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column justify-center align-center",
                  attrs: { cols: "12" },
                },
                [
                  _vm.$store.getters.getLogoClaro
                    ? _c("img", {
                        staticClass: "mx-4",
                        attrs: {
                          src: _vm.$store.getters.getLogoClaro,
                          width: "120",
                        },
                      })
                    : _vm._e(),
                  _c("h1", { staticClass: "white--text" }, [
                    _vm._v(_vm._s(_vm.$store.getters.getAppName)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "8" } },
                [
                  _c(
                    "validation-observer",
                    { ref: "form" },
                    [
                      _c(
                        "v-form",
                        {
                          attrs: { autocomplete: "off" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit()
                            },
                          },
                        },
                        [
                          _vm.temCredenciais
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "7" } },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Usuário",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages": errors,
                                                      name: "usuario",
                                                      "data-test-id": "usuario",
                                                      label: "Usuário",
                                                      maxlength: "200",
                                                      disabled:
                                                        _vm.temCredenciais &&
                                                        _vm.dispositivoMovel,
                                                      required: "",
                                                    },
                                                    model: {
                                                      value: _vm.form.username,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "username",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.username",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          990200940
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-none mt-5 font-weight-bold",
                                          attrs: {
                                            block: "",
                                            large: "",
                                            "data-test-id": "mudar",
                                            color: "white",
                                          },
                                          on: { click: _vm.apagarCredenciais },
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticClass: "primary--text",
                                              attrs: {
                                                "data-test-id": "mudar",
                                              },
                                            },
                                            [_vm._v("Mudar")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Senha",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      name: "password",
                                                      "data-test-id":
                                                        "password",
                                                      "append-icon":
                                                        _vm.showPassword
                                                          ? "mdi-eye"
                                                          : "mdi-eye-off",
                                                      type: _vm.showPassword
                                                        ? "text"
                                                        : "password",
                                                      label: "Senha",
                                                      maxlength: "100",
                                                      "error-messages": errors,
                                                      required: "",
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.showPassword =
                                                          !_vm.showPassword
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.form.password,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "password",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.password",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "white--text text-right cursor-pointer mt-n3 pr-1 esqueci-senha__button",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.abrirResetSenha()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Esqueceu sua senha?"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2180482475
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Usuário",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                "error-messages": errors,
                                                name: "usuario",
                                                "data-test-id": "usuario",
                                                label: "Usuário",
                                                maxlength: "200",
                                                disabled:
                                                  _vm.temCredenciais &&
                                                  _vm.dispositivoMovel,
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.form.username,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "username",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.username",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("validation-provider", {
                                    attrs: { name: "Senha", rules: "required" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                name: "password",
                                                "data-test-id": "password",
                                                "append-icon": _vm.showPassword
                                                  ? "mdi-eye"
                                                  : "mdi-eye-off",
                                                type: _vm.showPassword
                                                  ? "text"
                                                  : "password",
                                                label: "Senha",
                                                maxlength: "100",
                                                "error-messages": errors,
                                                required: "",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  _vm.showPassword =
                                                    !_vm.showPassword
                                                },
                                              },
                                              model: {
                                                value: _vm.form.password,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "password",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.password",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "white--text text-right cursor-pointer mt-n3 pr-1 esqueci-senha__button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.abrirResetSenha()
                                                  },
                                                },
                                              },
                                              [_vm._v("Esqueceu sua senha?")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none mb-10 mt-10 font-weight-bold",
                              attrs: {
                                block: "",
                                large: "",
                                "data-test-id": "entrar",
                                type: "submit",
                                elevation: "10",
                                color: "white",
                                loading: _vm.$store.getters.getLoadingGlobal,
                              },
                            },
                            [
                              _c(
                                "b",
                                {
                                  staticClass: "primary--text",
                                  attrs: { "data-test-id": "entrar" },
                                },
                                [_vm._v("Entrar")]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "loader" }, slot: "loader" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      width: 3,
                                      color: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.temCredenciais
                            ? _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-10",
                                      attrs: { rounded: true },
                                      on: { click: _vm.entrarComDigital },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "black" } },
                                        [_vm._v("mdi-fingerprint")]
                                      ),
                                      _c(
                                        "b",
                                        { staticClass: "primary--text mt-1" },
                                        [_vm._v("Entrar com digital")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none font-weight-bold primary--text",
                              attrs: {
                                text: "",
                                "data-test-id": "cadastrar",
                                large: "",
                                to: { name: "welcome" },
                                color: "white",
                              },
                            },
                            [_vm._v("Cadastrar-se")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer", { staticStyle: { width: "100%" } }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "esqueci-senha",
          attrs: { width: "320" },
          model: {
            value: _vm.redefinirSenha.dialog,
            callback: function ($$v) {
              _vm.$set(_vm.redefinirSenha, "dialog", $$v)
            },
            expression: "redefinirSenha.dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "primary--text pt-6" }, [
                _vm._v("Esqueci minha senha"),
              ]),
              _c("v-card-text", { staticClass: "esqueci-senha__conteudo" }, [
                _c(
                  "div",
                  { staticClass: "esqueci-senha__form p-" },
                  [
                    _c(
                      "validation-observer",
                      { ref: "esqueciSenha" },
                      [
                        _c("base-text-field", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "###.###.###-##",
                              expression: "'###.###.###-##'",
                            },
                          ],
                          ref: "cpf",
                          attrs: {
                            rules: "required|cpf",
                            id: "cpf",
                            inputmode: "numeric",
                            name: "CPF",
                            label: "CPF *",
                            clearable: "",
                          },
                          model: {
                            value: _vm.redefinirSenha.cpf,
                            callback: function ($$v) {
                              _vm.$set(_vm.redefinirSenha, "cpf", $$v)
                            },
                            expression: "redefinirSenha.cpf",
                          },
                        }),
                        _c("base-text-field", {
                          attrs: {
                            type: "email",
                            rules: "required|email",
                            id: "email",
                            name: "E-mail",
                            label: "E-mail *",
                            clearable: "",
                          },
                          model: {
                            value: _vm.redefinirSenha.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.redefinirSenha, "email", $$v)
                            },
                            expression: "redefinirSenha.email",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end mt-1" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "grey darken-1", text: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.redefinirSenha.dialog = false
                                  },
                                },
                              },
                              [_vm._v(" cancelar ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary darken-1",
                                  text: "",
                                  loading: _vm.redefinirSenha.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetarSenha()
                                  },
                                },
                              },
                              [_vm._v(" enviar ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "esqueci-senha__sucesso",
                    class: {
                      "esqueci-senha__sucesso--mostrar":
                        _vm.redefinirSenha.sucesso,
                    },
                  },
                  [
                    _c("div", { staticClass: "text-body-1" }, [
                      _vm._v(" Sua senha foi redefinida."),
                      _c("br"),
                      _vm._v(" A sua nova senha foi enviada para "),
                      _c("strong", [_vm._v(_vm._s(_vm.redefinirSenha.email))]),
                      _vm._v(". "),
                    ]),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mt-10 float-right",
                        attrs: { depressed: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.redefinirSenha.dialog = false
                          },
                        },
                      },
                      [_vm._v("ok")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "logo-novo-dark" }),
      _c("div", { staticClass: "logo-novo-light" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }